import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { ApplicationStatusesEnum } from '../Enums';
import { postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const Details = ({ setModal, notes, remarks }) => {
    const toggle = () => setModal(false);
    
    const [Loading, setLoading] = useState(false);
    
    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>
            <ModalHeader toggle={toggle}>Application Notes</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="form__form-group" style={{ marginTop: 0 }}>
                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                Internal Notes</span>
                            <div className="form__form-group-field">
                                <textarea
                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 100, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                    rows={4}
                                    autoComplete="off"
                                    placeholder=""
                                    disabled
                                    value={notes}
                                    //onChange={(txt) => { setnotes(txt.target.value); }}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form__form-group" style={{ marginTop: 0 }}>
                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                Remarks</span>
                            <div className="form__form-group-field">

                                <textarea
                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 100, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                    rows={4}
                                    autoComplete="off"
                                    placeholder=""
                                    disabled
                                    value={remarks}
                                    //onChange={(txt) => { setRemarks(txt.target.value); }}
                                ></textarea>
                            </div>
                        </div>
                    </Col>

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle} disabled={Loading}>Close</Button>
            </ModalFooter>
        </Modal>

    );
}
export default Details;