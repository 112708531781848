import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../scss/app.scss';
import Router from './Router';
import { Provider } from 'react-redux';
import store from './store';
import { FunctionsContextProvider } from '../../components/Context/Functions.context';
import { AuthProvider } from '../Context/local.context';


const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    // window.addEventListener('load', () => {
    //   setIsLoading(false);
    //   setTimeout(() => setIsLoaded(true), 500);
    // });
  }, []);

  return (
    <>
      {!isLoaded && (
        <div className={`load${isLoading ? '' : ' loaded'}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )}
      <div>
        <FunctionsContextProvider>
          <AuthProvider>
            <Provider store={store}>
              <Router />
            </Provider>
          </AuthProvider>
        </FunctionsContextProvider>
      </div>
    </>
  );
};

export default App;
