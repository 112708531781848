import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
const Auth = React.createContext({});

const AuthProvider = ({ children }) => {

    const [AuthData, setAuthData] = useState({ Buttons: [], Navigations: [] });
    useEffect(() => {
        var t = localStorage.getItem("authnav");
        if (t != "") {
            setAuthData(JSON.parse(t));
        }
    }, [])
    return (
        <Auth.Provider value={{
            AuthData,
            setAuthData

        }}>
            {children}
        </Auth.Provider>
    )
};

export {
    Auth,
    AuthProvider
};