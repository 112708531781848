import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import SeheduleMeeting from './ScheduleMeeting';
import { FunctionsContext } from '../../Context/functions.context'
import { postRequest } from '../service';
import { CheckBox, Fullscreen, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import AddBranchRef from './AddBranchRef';
import Uploadusers from './CSVupload';
import UploadusersExcel from './Excelupload'
import { Auth } from '../../Context/local.context';
import { ButtonList } from '../Enums';

const UserList = () => {
    const history = useHistory()
    const [usrList, setusrList] = useState([]);
    const [filterlist, setfilterlist] = useState([]);
    const [meetingmodel, setMeetingmodel] = useState(false);
    const [AddUsermodel, setAddUsermodel] = useState(false)
    const [AddUsermodelCSV, setAddUsermodelCSV] = useState(false)
    const [AddUsermodelExcel, setAddUsermodelExcel] = useState(false)

    const [search, setsearch] = useState("");
    const [selid, setSelid] = useState("");

    const [isLoaded, setisLoaded] = useState(false);
    const [Roles, setRoles] = useState([]);
    const { AuthData } = useContext(Auth)
    //const [Buttons, setButtons] = useState([]);
    useEffect(() => {
        //setButtons([...AuthData.Buttons]);
        list();
    }, [])
    async function list() {
        setisLoaded(false);
        var data = await postRequest("api/dashboard/DigitalAcountDashboard/GetBankReferrals", null);
        console.log(data);
        //if (data.StatusCode === 0) {
        setfilterlist([...data]);
        //}
        setisLoaded(true);
    }

    function download_table_as_csv(table_id, separator = ',') {

        var rows = filterlist;// document.querySelectorAll('table#' + table_id + ' tr');

        var csv = [];
        csv.push(["Employee Code and Name", "Employee Code",
            "Employee Name",
            "CNIC",
            // "Short Link",
            "Referral Link"].join(separator));
        for (var i = 0; i < rows.length; i++) {
            var d = rows[i];
            var row = [], cols = rows[i]

            row.push('"' + d["EmployeeCodeAndName"].replace(/"/g, '""')+ '"')
            row.push('"' +d["EmployeeCode"].replace(/"/g, '""')+ '"')
            row.push('"' +d["EmployeeName"].replace(/"/g, '""')+ '"')
            row.push('"' +d["CNIC"].replace(/"/g, '""')+ '"')
            // row.push('"' +d["ShortUrl"].replace(/"/g, '""')+ '"')
            row.push('"' +d["LongUrl"].replace(/"/g, '""')+ '"')
            // d["Email"],
            // d["Id"]                        

            //rows[i].querySelectorAll('td, th');
            // for (var j = 0; j < cols.length; j++) {
            //     // Clean innertext to remove multiple spaces and jumpline (break csv)
            //     var data = cols[j]//.innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            //     // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            //     data = data.replace(/"/g, '""');
            //     // Push escaped string
            //     row.push('"' + data + '"');
            // }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join('\n');

        var filename = 'Branch Referal List-' + new Date().toLocaleDateString() + '.csv';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        !isLoaded ? (
            <div style={{ textAlign: "center" }}>
                {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                <div className={`load${true ? '' : ' loaded'}`}>

                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            </div>
        ) :
            <div style={{}}>
                <div>
                    <Row>
                        <Col>
                            <Card style={{ marginRight: 15 }}>
                                <CardBody>
                                    <Row>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Col md={5}>
                                                <h3 className="page-title">Employee Ref Link List</h3>

                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Search</span>
                                                    <div className="form__form-group-field">
                                                        <input
                                                            disabled={!isLoaded}
                                                            style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                            name="name"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            required
                                                            value={search}
                                                            onChange={(txt) => { setsearch(txt.target.value); }}
                                                        />
                                                        {/* <button className="btn" style={{ marginLeft: 10, padding: "10px 20px", width: 110, marginBottom: 0, overflow: "none !important", backgroundColor: "var(--islamic)", color: "white" }}
                                                    onClick={() => {
                                                        setCurrentPage(1);
                                                        list(search);
                                                    }}
                                                >Search</button> */}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Col>
                                        <Col md={4} style={{ marginTop: 20 }}>
                                            {/* {AuthData.Buttons.findIndex(p => p === ButtonList.AddUser) !== -1 ? */}
                                            <>
                                                <button className='btn ' style={{ backgroundColor: "var(--faysal)", color: "white" }} onClick={() => { setAddUsermodel(true) }}>Add Employee Ref Link</button>
                                                <button className='btn ' style={{ backgroundColor: "var(--faysal)", color: "white" }} onClick={() => { setAddUsermodelCSV(true) }}>Add with CSV</button>
                                                <button className='btn ' style={{ backgroundColor: "var(--islamic)", color: "white" }} onClick={() => { download_table_as_csv("recordtable") }}>Download as CSV</button>
                                                <button className='btn ' style={{ backgroundColor: "var(--faysal)", color: "white" }} onClick={() => { setAddUsermodelExcel(true) }}>Add with Excel</button>

                                            </>
                                            {/* : <></>} */}
                                        </Col>
                                    </Row>
                                    <Row>
                                    </Row>

                                    <Table responsive hover bordered id="recordtable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Employee Code</th>
                                                <th>Employee Name</th>
                                                <th>CNIC</th>
                                                {/* <th>Short Link</th> */}
                                                <th>Referral Link</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {search !== "" ? filterlist.filter(i => i.EmployeeCode?.includes(search)
                                                || i.EmployeeName?.includes(search)
                                                || i.CNIC?.includes(search)
                                            ).map((p, i) => (
                                                <tr key={p.Id}>
                                                    <td><p>{i + 1}</p></td>
                                                    <td><p>{p.EmployeeCode}</p></td>
                                                    <td><p>{p.EmployeeName}</p></td>
                                                    <td><p>{p.CNIC}</p></td>
                                                    {/* <td><p>{p.ShortUrl}</p></td> */}
                                                    <td><p>{p.LongUrl}</p></td>
                                                </tr>

                                            ))
                                                :
                                                filterlist.map((p, i) => (
                                                    <tr key={p.Id}>
                                                        <td><p>{i + 1}</p></td>
                                                        <td><p>{p.EmployeeCode}</p></td>
                                                        <td><p>{p.EmployeeName}</p></td>
                                                        <td><p>{p.CNIC}</p></td>
                                                        {/* <td><p>{p.ShortUrl}</p></td> */}
                                                        <td><p>{p.LongUrl}</p></td>
                                                    </tr>

                                                ))

                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
                {
                    AddUsermodel == true ?
                        <AddBranchRef model={true} setModal={setAddUsermodel} userid={selid} roles={Roles} refreshlist={list} />
                        : null
                }

                {
                    AddUsermodelCSV == true ?
                        <Uploadusers model={true} setModal={setAddUsermodelCSV} userid={selid} roles={Roles} refreshlist={list} />
                        : null
                }
                {
                    AddUsermodelExcel == true ?
                        <UploadusersExcel model={true} setModal={setAddUsermodelExcel} userid={selid} roles={Roles} refreshlist={list} />
                        : null
                }
            </div >
    )
}

export default UserList;