import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import MoonNewIcon from 'mdi-react/LightbulbIcon';
import SunAdvisoryIcon from 'mdi-react/LightbulbOffIcon';
import Logo from '../../../assets/blue.png'

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility,changeToDark,changeToLight }) => {
  const [dark, setDark] = useState(false)  
  const changetheme=()=>{
    if(!dark){
      changeToDark()
      setDark(true);
    }else{
      changeToLight();
      setDark(false);
    }
  }
  return(
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <img src={Logo} style={{width:180,height:60}} id={"adminlogo"} />
      </div>

      <div className="topbar__right">
        <button type="button" style={{border:"none",backgroundColor:"transparent"}} onClick={() => {changetheme()}}>
          {dark?<MoonNewIcon color={"#fff"}/>:<SunAdvisoryIcon />}
        </button>
        <TopbarProfile />
      </div>
    </div>
  </div>
)
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
