import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import { ContineuResentOtp, ResentOtp, VerifyContinueOTP, VerifyOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { ApiDataContext } from "../Context/Api.context";


const BasicDetails = ({ route }) => {
    const { data, Bindata, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)

    const [otp, setotp] = useState("")
    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const [mnumber, setmnumber] = useState("");
    const [email, setemail] = useState("");
    useEffect(() => {
        debugger
        setActive("Basic Details & OTP Verification");
        setLoading(true);
        if (history.location.state?.phone) {
            async function a() {
                setmnumber(history.location.state.phone);
                setemail(history.location.state.email);
                var t = await ContineuResentOtp(history.location.state.cnic);
            }
            a();
        } else {
            history.push("/");
        }
        setLoading(false);
    }, [])
    useEffect(() => {
        setcountdown(60);
        var t = setInterval(() => {
            if (countdown > -1) {
                var s = countdown - 1;
                setcountdown(prevTime => prevTime - 1);
            }
        }, 1000);
        var s = setTimeout(() => {
            clearInterval(t);
            clearTimeout(s)
        }, 1000 * 60);
        return () => {
            clearInterval(t);
            clearTimeout(s)
        }
    }, []);

    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        var resp = await VerifyContinueOTP(data.cnic, otp);

        
        if (resp.StatusCode === 0) {
            var o = await Bindata(resp.ResponseObject);
            if("basicdetails"===resp.ResponseObject.currentScreen){
                history.push("/accountoption");
            }else{
                history.push("/" + resp.ResponseObject.currentScreen);
            }
            
        }
        setLoading(false);
    }

    const resend = async () => {

        setLoading(true);
        var resp = await ResentOtp(data);
        setLoading(false);
        
        setcountdown(60);
        var t = setInterval(() => {
            if (countdown > -1) {
                var s = countdown - 1;
                setcountdown(prevTime => prevTime - 1);
            }
        }, 1000);
        var s = setTimeout(() => {
            clearInterval(t);
            clearTimeout(s)
        }, 1000 * 60);
    }
    return (
        <Animatedpage submit={datasubmit} useauth={true}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        <p className="Qhelper">Phone Number : <b>{mnumber}</b></p>
                        <p className="Qhelper">Email : <b>{email}</b></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> / 1</p> */}
                        <p className="Qhelper">Enter the OTP code sent to you mobile number.</p>
                        <input className="inputtxt" name="otp" type={"password"} minLength={4} maxLength={4} onChange={(txt) => setotp(txt.target.value)} required />
                        {countdown === 0 ? <p onClick={() => resend()} className="Qhelper" style={{ cursor: "pointer" }}>Resent OTP</p> : <p className="Qhelper">OTP expire in 00:{countdown < 10 ? "0" + countdown : countdown}</p>}

                    </div>
                    <label for="otpchk" className="" style={{ marginBottom: 20 }}>
                        <input type="checkbox" id="otpchk" name="otpchk" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required />
                        All correspondence with the customer will be carried out on above-mentioned email address and mobile no. unless changed in the future by the customer.
                    </label >
                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("basicdetails")}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>
        </Animatedpage >
    )

}
export default BasicDetails;