const ApplicationStatuses = {
    applicationSubmitted: "Application Submitted",
    applicationDraft: "Application Draft",
    applicationProcess: "Application In Process",
    applicationDiscrepant: "Application Discrepant",
    applicationSuccessful: "Application Successful",
    applicationClosed: "Application Closed",
    application_rejected:"Application Rejected"

};
const ApplicationStatusesEnum = {
    Application_Draft: 1,
    Application_Submitted: 2,
    Application_in_Process: 3,
    Application_Discrepant: 4,
    Application_Successful: 5,
    Application_Closed: 6,
    Application_Rejected: 7
}
const ApplicationStatusList = [
    // { name: "Application Draft", id:1 },
    { name: "Application Submitted", id :2 },
    { name: "Application In Process", id :3 },
    { name: "Application Discrepant", id :4 },
    { name: "Application Successful", id :5 },
    { name: "Application Closed", id : 6 },
    { name: "Application Rejected", id: 7 }

]
const ApplicationStatusList2 = [
    { name: "Application Draft", id:1 },
    { name: "Application Submitted", id :2 },
    { name: "Application In Process", id :3 },
    { name: "Application Discrepant", id :4 },
    { name: "Application Successful", id :5 },
    { name: "Application Closed", id : 6 },
    { name: "Application Rejected", id: 7 }

]
const ButtonList = {
    UsersDetail: 1,
    ViewUserRole: 2,
    AddUserRole: 3,
    RemoveUserRole: 4,
    UpdateUserStatus: 5,
    AddUser: 6,
}
export {
    ApplicationStatuses,
    ButtonList,
    ApplicationStatusList,
    ApplicationStatusesEnum,
    ApplicationStatusList2
}