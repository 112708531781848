import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import LineChart from './components/LineChartOfAcounts'
import Barchart from './components/GenderwiseBarchart'
import Piechart from './components/PieAccountTypeChart'
import DailyPiechart from './components/PieDailyAccountTypechart'
import { postRequest } from '../service';
const Dashboard = () => {
    const [data, setData] = useState(null)
    useEffect(async () => {
        var s = await postRequest("api/dashboard/DigitalAcountDashboard/GetDashboardData", {});
        setData(s.ResponseObject);


    }, [])

    return (
        <div style={{}}>
            <div>
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Dashboard</h3>
                    </Col>
                </Row>

                <div class="row">
                    <div class="col-md-6 col-xl-3">
                        <div class="card-stats card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="text-uppercase mb-0 card-title">Accounts Created</h5>
                                        <span class="font-weight-bold mb-0"><h2>{data ? data?.GetTotalAccounts?.TotalAccounts : 0}</h2></span>
                                    </div>
                                    <div class="col-auto col">
                                        <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                            <i class="ni ni-active-40"></i>
                                        </div>
                                    </div>
                                </div>
                                <p class="mt-3 mb-0 text-sm"><span class="text-success mr-2"><i class="fa fa-arrow-up"></i>{data ? data?.GetAccountsDifferenceByYear?.TotalAccountCreatedToday : 0}%</span>
                                    <span class="text-nowrap">Since last Year</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card-stats card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="text-uppercase mb-0 card-title">New users</h5>
                                        <span class="font-weight-bold mb-0"><h2>{data ? data?.GetAccountsCreatedByDay?.TotalAccounts : 0}</h2></span></div>
                                    <div class="col-auto col">
                                        <div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                            <i class="ni ni-chart-pie-35"></i>
                                        </div>
                                    </div>
                                </div>
                                <p class="mt-3 mb-0 text-sm">
                                    <span class="text-success mr-2">
                                        <i class="fa fa-arrow-up"></i>{data ? data?.GetNewUsersDifferenceByMonth?.TotalUsersDifferenceByMonth : 0}%</span>
                                    <span class="text-nowrap">Since last month</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card-stats card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="text-uppercase mb-0 card-title">Account Created</h5>
                                        <span class="font-weight-bold mb-0"><h2>{data ? data?.GetAccountsCreatedByDay?.TotalAccounts : 0}</h2></span>
                                    </div>
                                    <div class="col-auto col">
                                        <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i class="ni ni-money-coins"></i></div></div></div><p class="mt-3 mb-0 text-sm">
                                    <span class="text-success mr-2">
                                        <i class="fa fa-arrow-up"></i> {data ? data?.TotalAccountDifferenceByDay?.TotalAccountPercentageDifferenceByDay : 0}%</span>
                                    <span class="text-nowrap">Since last 24 hours</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card-stats card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5 class="text-uppercase mb-0 card-title">Account Increment</h5>
                                        <span class="font-weight-bold mb-0"><h2>{parseFloat(data ? data?.TotalAccountDifferenceByMonth?.TotalAccountDifferenceByMonth : 0).toFixed(2)}%</h2></span>
                                    </div>
                                    <div class="col-auto col">
                                        <div class="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                            <i class="ni ni-chart-bar-32"></i>
                                        </div>
                                    </div>
                                </div>
                                <p class="mt-3 mb-0 text-sm"><span class="text-success mr-2"><i class="fa fa-arrow-up"></i></span>
                                    <span class="text-nowrap">Since last month</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <Row sm={12} lg={12} md={12}>
                    <Col sm={12} lg={12} md={12}>
                        <Card>
                            <CardHeader style={{ textAlign: "center" }}>
                                <h5> Current And Previous Year Accounts</h5>
                            </CardHeader>
                            <CardBody>
                                <LineChart sdata={data?.GetAccountTypeByYear} />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm={12} lg={12} md={12}>
                        <Card>
                            <CardHeader style={{ textAlign: "center" }}>
                                <h5> Users Accounts Gender Wise</h5>
                            </CardHeader>
                            <CardBody>
                                <Barchart sdata={data?.GetGenderbaseAccount} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row sm={12} lg={12} md={12}>
                    <Col sm={12} lg={6} md={6}>
                        <Card>
                            <CardHeader style={{ textAlign: "center" }}>
                                <h5>Monthly Account By Type</h5>
                            </CardHeader>
                            <CardBody>
                                <Piechart sdata={data?.GetAccountTypeByMonth}/>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm={12} lg={6} md={6}>

                        <Card>
                            <CardHeader style={{ textAlign: "center" }}>
                                <h5>Weekly Account By Type</h5>
                            </CardHeader>
                            <CardBody>
                                <DailyPiechart sdata={data?.GetAccountTypeByWeek} />
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
        </div>
    );
};

export default Dashboard;

