

import React, { useContext, useEffect, useState } from "react";
import { ApiDataContext } from "./Api.context";

const DataContext = React.createContext({});

const DataContextProvider = ({ children }) => {
    const { ApiData } = useContext(ApiDataContext)


    const [data, setData] = useState({
        selectedAT: "1",
        option: "1",
        category: 1,
        currency: "1",
        type: "1",
        cnic: "",
        purpose: "1",
        mnumber: "",
        Occupation: "Salaried",
        NutureTrans: [],
        ATMReason: [],
        Checkbook: "Yes",
        ATM: "Yes",
        ATMCard: "Soneri Pay Pak Debit",
        kinnumber: "",
        landline: "",
        nationality: "",
        resident: "",
        Zakat: "yes",
        FATCADeclarations: "",
        FATCAStatus: "",
        officenumber: ""
    });
    useEffect(() => {

        checkExpiration();

        var t = localStorage.getItem("dataCon");
        if (t !== null) {
            setData(JSON.parse(t));
        }

    }, [])
    const setvalue = (txt) => {
        var value = txt.target.value;
        var name = txt.target.name;
        var type = txt.target.type;
        if (type === "file") {
            var t = data;
            t[name] = txt.target.files;
            setData({ ...t });
        }
        else if (type === "checkbox") {
            var t = data;
            if (txt.target.checked) {
                t[name] = value;
            } else {
                t[name] = "";
            }
            setData({ ...t });
        }
        else {
            var t = data;
            t[name] = value;
            setData({ ...t });
        }
    }
    const setmultiple = (txt, name) => {
        var t = data;
        t[name] = txt;
        setData({ ...t });
       
    }

    function Bindata(resdata) {
        return new Promise((resolve, reject) => {
            var AccountDetails = resdata.data.AccountDetails;
            var BeneficialDetails = resdata.data.BeneficialDetails;
            var CardAndChequeBookDetails = resdata.data.CardAndChequeBookDetails;
            var IndividualInformationPart_II = resdata.data.IndividualInformationPart_II;
            var IndividualInformationPart_III = resdata.data.IndividualInformationPart_III;
            var RemitterDetails = resdata.data.RemitterDetails;
            var ZakatAndTransactionDetails = resdata.data.ZakatAndTransactionDetails;
            var IndividualInformationPart_I = resdata.data.IndividualInformationPart_I;


            var d = {
                NutureTrans: [],
                ATMReason: [],
                FATCADeclarations: "",
                FATCAStatus: "",
            };
            if (AccountDetails !== null) {
                debugger
                d = {
                    ...d,
                    // "selectedAT": ApiData.Banking.filter(p => p.id === AccountDetails.BankingType)[0],
                    "option": ApiData.Producttype.filter(p => p.Id === AccountDetails.ProductTypeId)[0],
                    "currency": AccountDetails.Currency,
                    "type": AccountDetails.AccountType,
                    "category": AccountDetails.AccountCategory,
                    "customerName": resdata.Userdata.CustomerName,
                    "cnic": resdata.Userdata.CNIC,
                    "issuancedate": AccountDetails.NadraIssuanceDate.split('T')[0],
                    "dateofbirth": resdata.Userdata.DateOfBirth.split('T')[0],
                    "mnumber": AccountDetails.PhoneNumber.slice(2),
                    "email": AccountDetails.Email,
                    "expirydate": AccountDetails.ExpiryDate.split('T')[0],
                    "mother": AccountDetails.MotherMaidenName,
                    "father": AccountDetails.FatherHusbandName,
                    "pob": AccountDetails.PlaceOfBirth,
                    "gender": AccountDetails.Gender,
                    "Marital": AccountDetails.MaritalStatus,
                    "PresentResidential": AccountDetails.PresentResidentialAddress,
                }
            }
            if (IndividualInformationPart_I) {
                d = {
                    ...d,
                    "nutureRes": IndividualInformationPart_I.NatureOfTransactions,// context.,
                    "EstimatedIncome ": IndividualInformationPart_I.EstimatedAnnualIncome,
                    "MonthlyCredittrans ": IndividualInformationPart_I.ExpectedMonthlyCreditTransactions,
                    "MonthlyDebittrans ": IndividualInformationPart_I.ExpectedMonthlyDebitTransactions,
                    "MonthlyCredit ": IndividualInformationPart_I.ExpectedMonthlyCredit,
                    "MonthlyDebit ": IndividualInformationPart_I.ExpectedMonthlyDebit,
                    "Branchname ": IndividualInformationPart_I.BranchCode,
                    "Branchref ": IndividualInformationPart_I.BranchRef,
                }
            }

            if (IndividualInformationPart_II !== null) {
                d = {
                    ...d,
                    "nationality": IndividualInformationPart_II.Nationality,
                    // "resident": IndividualInformationPart_II.ResidentCountry,
                    "OtherResident": IndividualInformationPart_II.OtherNationality,
                    "education": IndividualInformationPart_II.Education,
                    "landline": IndividualInformationPart_II.Landline,
                    "PermanentResidential": IndividualInformationPart_II.PermanentResidentialAddress,
                }
            }

            if (IndividualInformationPart_III !== null) {
                d = {
                    ...d,
                    // "employeer": IndividualInformationPart_III.EmployeerName,
                    // "officeaddress": IndividualInformationPart_III.OfficeAddress,
                    // "officenumber": IndividualInformationPart_III.OfficePhone,
                    "Occupation": IndividualInformationPart_III.Occupation,
                    "Income": IndividualInformationPart_III.SourceOfIncome,
                    "kinname": IndividualInformationPart_III.NextofKinName,
                    "kinaddress": IndividualInformationPart_III.NextOfKinAddress,
                    "kinrelation": IndividualInformationPart_III.NextOfKinRelation,
                    "kinnumber": IndividualInformationPart_III.NextOfKinContactNumber,
                }
            }
            if (BeneficialDetails !== null) {
                d = {
                    ...d,
                    "BeneficialName": BeneficialDetails.Name,
                    "BeneficialAddress": BeneficialDetails.Address,
                    "BeneficialMobile": BeneficialDetails.Mobile,
                    "BeneficialLegalID": BeneficialDetails.LegalIdNo,
                    "BeneficialRelationship": BeneficialDetails.Relationship,
                }
            }
            if (ZakatAndTransactionDetails !== null) {
                d = {
                    ...d,
                    "Zakat": ZakatAndTransactionDetails.ZakatExemption === true ? "1" : "0",
                    "Zakatreason": ZakatAndTransactionDetails.ExemptionReason,
                    "FATCAStatus": ZakatAndTransactionDetails.FATCAStatus,
                    "FATCADeclarations": ZakatAndTransactionDetails.FATCADeclarations,
                    "taxResidence": ZakatAndTransactionDetails.countryTaxResidence === null ? 0 : ZakatAndTransactionDetails.countryTaxResidence.Country,
                    "TIN": ZakatAndTransactionDetails.TIN,
                    "TINReason": ZakatAndTransactionDetails.TINReason,
                    "TINremarks": ZakatAndTransactionDetails.TINRemarks
                }
            }
            if (RemitterDetails !== null) {
                d = {
                    ...d,
                    "remitterName": RemitterDetails.Name,
                    "remitterRelation": RemitterDetails.Relationship,
                    "remitterlegalID": RemitterDetails.LegalId,
                }
            }

            if (CardAndChequeBookDetails !== null) {
                d = {
                    ...d,
                    "Checkbook": CardAndChequeBookDetails.ChequeBook === true ? "1" : "0",
                    "CheckbookLeave": CardAndChequeBookDetails.ChequeBookNoOfLeaves === "1" ? CardAndChequeBookDetails.ChequeBookNoOfLeaves : 0,
                    "ATM ": CardAndChequeBookDetails.ATMDebitCard === true ? "1" : "0",
                    "EFT ": CardAndChequeBookDetails.EFT === true ? "1" : "0",
                    "ESS ": CardAndChequeBookDetails.ESS === true ? "1" : "0",
                    "ESF ": CardAndChequeBookDetails.ESF,
                    "atmres": CardAndChequeBookDetails.CardReason,//context.,
                    "ATM ": CardAndChequeBookDetails.ATMDebitCard === true ? ApiData.CardTypeDetailList.ResponseObject.filter(p => p.Id === CardAndChequeBookDetails.DebitCardType)[0] : {},
                    "purpose ": CardAndChequeBookDetails.PurposeOfAccount
                }
            }
           
            setData({ ...d });
            resolve();
        })
    }

    function checkExpiration() {
        var values = JSON.parse(localStorage.getItem('ExpiryTime'));
        if (new Date(values?.expiry) < new Date()) {
            localStorage.removeItem("dataCon")
            localStorage.removeItem("ExpiryTime")

        }
    }
    useEffect(() => {
        updateDataTolocal();
    }, [data])
    const updateDataTolocal = () => {
        debugger
        var myHour = new Date();
        var now = new Date();
        myHour.setHours(now.getHours() + 1);
        localStorage.setItem('ExpiryTime', JSON.stringify({ expiry: myHour.getTime() }));
        localStorage.setItem("dataCon", JSON.stringify(data))
    }

    return (
        <DataContext.Provider value={{
            data,
            setData,
            setvalue,
            Bindata,
            setmultiple
        }}>
            {children}
        </DataContext.Provider>
    )
};

export {
    DataContext,
    DataContextProvider
};