import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { CheckAplhaChara, postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const SeheduleMeeting = ({ modal, setModal, id, index }) => {
    const toggle = () => setModal(false);
    const [datetime, setdatetime] = useState("");
    const [notes, setnotes] = useState("");
    const submit = async () => {
        if (datetime !== "") {
            var data = { "Id": id, "Index": index, "Date": datetime, "Notes": notes };
            debugger;
            var result = await postRequest("api/dashboard/DigitalAcountDashboard/SendScheduleMeeting", data);
            if (result.StatusCode !== 0) {
                alert("Incounter some error. please try again.")
            } else {
                alert("Meeting Scheduled.")
                toggle();
            }
        } else {
            alert("Meeting Date/Time is required.")
        }
    }
    useEffect(() => {
    }, [])
    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>
            <ModalHeader toggle={toggle}>Schedule Meeting</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="form__form-group" style={{ marginTop: 0 }}>
                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                Meeting Date/Time</span>
                            <div className="form__form-group-field ">
                                <input
                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                    type="datetime-local"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    className="mdate"
                                    value={datetime}
                                    onChange={(txt) => { setdatetime(txt.target.value); }}
                                />
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <div className="form__form-group" style={{ marginTop: 0 }}>
                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                Meeting Notes</span>
                            <div className="form__form-group-field">
                                <textarea
                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                    rows={4}
                                    autoComplete="off"
                                    placeholder=""

                                    value={notes}
                                    onChange={(txt) => {CheckAplhaChara(txt, () => {  setnotes(txt.target.value) }, () => { });}}
                                ></textarea>
                            </div>
                        </div>
                    </Col>

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => submit()}>Schedule</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>

    );
}
export default SeheduleMeeting;