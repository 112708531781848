import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, PhoneAndroid } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory, useLocation } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import "./CNIC.css";
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import { CheckNumeric, ResentOtp } from "../service";

const BasicDetails = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading, DynamicModelShow, DynamicModelHide } = useContext(AlertDataContext);
    const { data, setData, setvalue } = useContext(DataContext);

    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const [sucess, setSucess] = useState(false)
    const location = useLocation();
    const [otp, setotp] = useState("")

    const [varify, setVarify] = useState(true)
    const [text, setText] = useState("Record does not exist against CNIC/MSISDN paring on Target CMO.");
    const { CheckCNIC } = useContext(FunctionsContext)
    useEffect(() => {

        setActive("");

    }, [])
    useEffect(async () => {
        // showChangenumberModel();
        var t =await CheckCNIC();        
        if(t.StatusCode === 0){
            history.replace("thankyou",{
                Ref:t.ResponseObject.RefNo
            });
        }else{
            setVarify(false)
            setText(t.StatusMessage);
        }
    }, [])

    const checkotp = (event) => {
        event.preventDefault();
        history.push("thankyou")

    }
    return (
        <Animatedpage submit={checkotp}>
            <Grid item xs={12} id="s1q2">
                <Row>
                    {varify ?
                        <div style={{ textAlign: "center", paddingTop: 30 }}>
                            <h4 style={{ margin: "auto" }}>{!sucess ? "Verifying CNIC-SIM Pairing" : "Verification Successful"}</h4>
                            <div className="containerload">
                                <div className="loading-bar">
                                    <div className="progress-bar"></div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div style={{ textAlign: "center", paddingTop: 30 }}>
                                <h4 style={{ margin: "auto", color: "red" }}>{"Sim Ownership Verification Failed"}</h4>
                                <h3 className="Q" style={{ margin: "auto", marginTop: 10, textAlign: "center" }}>CNIC Number does not match with your provided Mobile Number</h3>
                            </div>
                        </>
                    }
                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default BasicDetails;