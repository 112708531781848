import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { getFileData, KeyValue, postRequest } from '../service';
// import { app } from '../../containers/Services';
// import sample from '../../assets/Faysal Bank Required Fields Sample.xlsx'
import './model.css';
const Uploadusers = ({ modal, setModal, roles, refreshlist }) => {
    const toggle = () => setModal(false);
    const [isLoaded, setIsLoaded] = useState(true)
    const [Data, setData] = useState([])
    function hasExtension(inputID, exts) {
        var fileName = document.getElementById(inputID).value;
        return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
    }

    const uploaddata = async (event) => {

        event.preventDefault();

        if (document.getElementById("csvfile").files[0] !== null) {
            var request_data = new FormData();
            var BranchRefExcel = new KeyValue("BranchRefExcel", document.getElementById("csvfile")?.files[0]);
            var data = {
                "Date": new Date().toDateString()
            };
            request_data = getFileData(data, BranchRefExcel);
            setIsLoaded(false)
            var data = await postRequest("api/dashboard/DigitalAcountDashboard/AddBankReferralsExcel", request_data);
            if (data.StatusCode === 0) {
                alert("Users Added.")
                refreshlist()
                toggle();
            } else {
                alert(data.StatusMessage);
            }
        } else {
            alert("No data found");
        }
        setIsLoaded(true)


    }

    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>

            <form onSubmit={uploaddata}>
                <ModalHeader>Add User Excel</ModalHeader>
                <ModalBody style={{ width: "fit-content" }}>
                    {!isLoaded ? (
                        <div style={{ textAlign: "center" }}>
                            {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                            <div className={`load${true ? '' : ' loaded'}`}>

                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ) :
                        <>
                            <input type={"file"} accept=".xlsx,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id='csvfile' name='csvfile' multiple={false} />
                            <br />
                            {/* <a href={sample} download>Download sample file</a> */}
                        </>
                        /*<>
                           
                            <Row>

                                <table className='table table-bordered table-stripe' style={{ marginTop: 50 }}>
                                    <thead>
                                        <tr>

                                            <th>EmployeeCode</th>
                                            <th>EmployeeName</th>
                                            <th>Email</th>
                                            <th>CNIC</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.map(p => (
                                            <tr>
                                                <td>{p.EmployeeCode}</td>
                                                <td>{p.EmployeeName}</td>
                                                <td>{p.Email}</td>
                                                <td>{p.CNIC}</td>

                                            </tr>

                                        ))}


                                    </tbody>
                                </table>


                            </Row>
                        </>*/
                    }
                </ModalBody>
                <ModalFooter>
                    <Col md={12}>
                        <button type='submit' className="btn" style={{ marginLeft: 5, backgroundColor: "var(--faysal)", color: "white" }}>Upload</button>
                        <button className="btn" style={{ marginLeft: 5, backgroundColor: "var(--islamic)", color: "white" }} onClick={toggle}>Cancel</button>
                    </Col>
                </ModalFooter>
            </form>

        </Modal>

    );
}
export default Uploadusers;