import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowLeft, ArrowRight, AssignmentInd, CheckBox, Email, PhoneAndroid } from "@material-ui/icons";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { StepsContext } from "../Context/Steps.Context";
import { useHistory } from "react-router";
import Animatedpage from "../animatepage";

import CardDoc from '../../Assets/Documents/Debit Card Product Description (1).pdf'
import FAQs from '../../Assets/Documents/Digital Onboarding FAQs- Final.pdf'
import BriefDoc from '../../Assets/Documents/Products Brief Description - Digital Onboarding Final.pdf'

import Recaptcha from "react-recaptcha";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { Config } from "../congig";
import { CheckAplhaChara, CheckNumeric, CheckPhone } from "../service";
import { screen } from "./screen";
import CustomCheck from "../customComponents/CustomCheck";
import { ApiDataContext } from "../Context/Api.context";
const publicIp = require("react-public-ip");

const AccountType = () => {
    const { showInfo, showError, setLoading, DynamicModelShow, DynamicModelHide } = useContext(AlertDataContext);
    const { FirstScreen } = useContext(FunctionsContext)
    const { setActive } = useContext(StepsContext);
    const { data, setData, setvalue } = useContext(DataContext);
    const [Captcha, setCaptcha] = useState(false)
    const [Accounttypelist, setAccounttypelist] = useState([]);
    const { ApiData } = useContext(ApiDataContext)
    const [layout, setLayout] = useState(false);
    const history = useHistory();
    useEffect(async () => {
        setActive("");

    }, [])
    const CNICFoucusOut = async () => {
        if (data?.cnic.length < 13) {
            return
        }
        setLoading(true);
        var resp = await CheckPhone(data);
        //setIsCNICCheck(true);

        if (resp.StatusCode === 0) {
            if (resp.ResponseObject.Exist === true) {
                var t = (<>
                    <p id="server-modal-description">The following number you entered is registered with another application. Would you like to continue with the previous application.</p>
                    <button style={{ margin: 10, background: "var(--secondary)", color: "#fff", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => {
                        DynamicModelHide();
                        history.push("/")
                    }} >
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Cancel
                    </button>
                    <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                        onClick={() => {
                            async function click() {
                                setLoading(true);
                                DynamicModelHide();

                                history.push("/continueotp", {
                                    email: resp.ResponseObject.Email,
                                    phone: resp.ResponseObject.PhoneNo,
                                    cnic: data.cnic
                                });
                                setLoading(false);


                            }
                            click();
                        }}
                    >Continue
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button >
                </>)
                setLoading(false);
                DynamicModelShow("Number Already Exist",
                    t
                    , false);

            } else {
                // DynamicModelHide()
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    var callback = function () {

    };
    var verifyCallback = function (response) {
        
        setCaptcha(true);
    };
    const datasubmit = async (event) => {
        // event.preventDefault();

        var url_string = window.location.href;
        var url = new URL(url_string);
        var ReferralId = url.searchParams.get("ReferralId");
        // setData({ ...data, ReferralId: c });
        if (enable) {
            history.push("accountoption");
            return;
        }
        if (!Captcha && Config.robot) {
            alert("Please validate Captcha.");
            return;
        }
        const ipv4 = await publicIp.v4()
        setData({ ...data, UserIp: ipv4 });

        setLoading(true)
        var resp = await FirstScreen(data, ReferralId);
        setLoading(false);
        if (resp.StatusCode === 0) {
            history.push("otp")
        }
    }
    const enable = 0 < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;
    return (
        <Animatedpage submit={datasubmit} pop={() => setLayout(true)}>
            {layout ?
                <>
                    <Grid item xs={12} style={{ marginBottom: 0 }} id="preferedbanking">
                        <Row className="slide">
                            <fieldset disabled={enable}>

                                <div>
                                    <div className="inputrow">
                                        <div className="inputcolumn1">
                                            <AssignmentInd className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                        </div>
                                        <div className="inputcolumn2">
                                            <h1 className="Q">CNIC Number</h1>
                                            <input className="inputtxt" minLength={13} maxLength={13} value={data.cnic} name="cnic" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required onBlur={CNICFoucusOut} />
                                            <p className="Qhelper2">Enter ID number without dashes.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <PhoneAndroid className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">WhatsApp / Mobile Number</h1>
                                        {/* <input className="inputtxt" type="tel" maxLength={13} minLength={13} name="mnumber" value={data.mnumber} onChange={(txt) => setvalue(txt)} required /> */}
                                        <div>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend" style={{ marginTop: 5 }}>
                                                    <span class="input-group-text">+92</span>
                                                </div>
                                                <input class="inputtxt form-control" type="tel" maxLength={10} minLength={10} placeholder="" style={{ borderLeft: "none !important" }}
                                                    value={data.mnumber} name="mnumber" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required />
                                            </div>

                                            <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_ESF_1"} setvalue={setvalue} required={true} />
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className="inputrow">
                                        <div className="inputcolumn1">
                                            <Email className="Iconcls" style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                        </div>
                                        <div className="inputcolumn2">
                                            <h1 className="Q">Email Address</h1>
                                            <input className="inputtxt" type="email" name="email" value={data.email} onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                                            <p className="Qhelper2">Enter a valid email. It will be use to contact you.</p>

                                            {Config.robot ?
                                                <Recaptcha

                                                    sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                                                    render="explicit"
                                                    verifyCallback={verifyCallback}
                                                    onloadCallback={callback}
                                                /> : null}
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <button variant="contained" style={{ margin: 10, background: "var(--primary)", color: "white", border: "none", borderRadius: 10, marginTop: 10, position: "absolute", right: 50 }} >Next
                                <ArrowRight />
                            </button>
                        </Row>
                    </Grid>
                    <Grid item xs={12} id="footermain" style={{ background: "var(--light)", position: "absolute", height: 130, bottom: 0, right: 0, width: "60%" }}>
                        <Row style={{ padding: 10, marginLeft: "0% !important" }}>
                            <div>
                                <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={CardDoc} download>Debit Card Product Description</a></h5>
                                <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={FAQs} download>Digital Onboarding FAQs</a></h5>
                                <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={BriefDoc} download>Products Brief Description</a></h5>

                            </div>
                        </Row>
                    </Grid>
                </>
                : <></>}
        </Animatedpage>
    )
}

export default AccountType;