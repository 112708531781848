import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { Auth } from '../../../Context/local.context';

const SidebarContent = ({ onClick, changeToDark, changeToLight }) => {
  const handleHideSidebar = () => {
    onClick();
  };
  const {AuthData} = useContext(Auth)
  const [navlist, setNavlist] = useState(AuthData.Navigations);
  useEffect(() => {
   
  }, [])
  return (
    <div className="sidebar__content">
      {/* <ul className="sidebar__block">        
        <SidebarCategory title="Layout" icon="layers">
          <button type="button" className="sidebar__link" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory>
      </ul> */}
      <ul className="sidebar__block">
      {
        AuthData.Navigations.map(p=>(
          <SidebarLink title={`${p.Name}`} icon="diamond" route={`/admin/${p.FrontEndUrl}`} onClick={handleHideSidebar} />                                  
        ))
      }
        

      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
