import React, { useContext, useEffect, useState } from "react";
import { Chip, Grid, MenuItem } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import Select from '@material-ui/core/Select';
import Input from "reactstrap/lib/Input";

import W8 from '../../Assets/Documents/FATCA Form W8.pdf'
import W9 from '../../Assets/Documents/FATCA Form W-9.pdf'
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { ApiDataContext } from "../Context/Api.context";
import { screen } from "./screen";
import { CheckAplhaChara, CheckNumeric } from "../service";
const ZakatNTrans = () => {

    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { ZakatNTrans } = useContext(FunctionsContext)
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const [Layout, setLayout] = useState(false)
    const history = useHistory();
    const enable = screen[history.location.pathname.replace("/", "")] < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;

    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        var resp = await ZakatNTrans(data);
        setLoading(false);        
        if (resp.StatusCode === 0) {
            history.push(data.option.AccountType === "Asaan Digital Remittance" ? "remitter" : "cnc")
        }
    }
    useEffect(() => {
        if (data.currency !== "1" && data.type === "1") {
            setData({ ...data, Zakat: false });
        }
        setActive("Zakat & FATCA Status");
    }, [ApiData])
    return (
        <>
            <Animatedpage submit={() => { !enable ? datasubmit() : history.push(data.option.AccountType === "Asaan Digital Remittance" ? "remitter" : "cnc") }} pop={() => setLayout(true)}>
                {Layout ?
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <fieldset disabled={enable}>
                                {data.type === "1" ? <></> :
                                    <>
                                        <div>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                            <h1 className="Q">Zakat Exemption</h1>
                                            <select className="inputtxt" name="Zakat" onChange={(txt) => setvalue(txt)} required
                                                disabled={data.type === "1"||data.type === 1 ? true : false}
                                            >
                                                <option value="">Select</option>

                                                <option value={"0"} selected={data.Zakat === "0"}>No</option>
                                                <option value={"1"} selected={data.Zakat === "1"}>Yes</option>
                                            </select>
                                            <p className="Qhelper2"></p>
                                        </div>
                                        {data.Zakat === "1" ?
                                            <div>
                                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                                <h1 className="Q">Reason For Exemption</h1>
                                                <select className="inputtxt" name="Zakatreason" onChange={(txt) => setvalue(txt)} required
                                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                                >
                                                    <option value="">Select</option>

                                                    {ApiData.ExemptionList.map(p => (
                                                        <option value={p.id} selected={data.Zakatreason === p.id}>{p.Name}</option>
                                                    ))}
                                                </select>
                                                <p className="Qhelper2"></p>

                                                {(data.Zakatreason === "2") ?
                                                    <>
                                                        <label for="occchk" className="Qhelper">
                                                            <input type="checkbox" id="occchk" name="ZakatExemptionCheck" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required />
                                                            I do hereby solemnly affirm that, I am a Non-Muslim and exempt from the compulsory deduction of Zakat.
                                                        </label >
                                                    </> :
                                                    <>
                                                        <label for="occchk" className="Qhelper">
                                                            <input type="checkbox" id="occchk" name="ZakatExemptionCheck" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} />
                                                            Please submit Zakat Declaration CZ-50 on Judicial Stamp paper as per format.
                                                        </label >
                                                        <div>
                                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>7</b> /7</p> */}
                                                            <h1 className="Q">Attach Zakat Declaration CZ-50</h1>
                                                            <input type={"file"} multiple={false} id="declarationCZ" name="declarationCZ-50" onChange={(txt) => setvalue(txt)} required />
                                                            <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Please attach Declaration CZ-50 in (PNG, PDF, BMP, GIF or JPEG).</p>
                                                        </div>
                                                    </>
                                                }
                                            </div> : <></>}
                                    </>
                                }

                                <div style={{ marginTop: 20 }}>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                    <h1 className="Q">FATCA Status</h1>
                                    {/* <input className="inputtxt" type="text" name="pob" onChange={(txt) => setvalue(txt)} required /> */}
                                    <select className="inputtxt" name="FATCAStatus" onChange={(txt) => {
                                        setvalue(txt);
                                        if (txt.target.value === "2") {
                                            setData({ ...data, FATCADeclarations: "1" });
                                        } else if (txt.target.value === "3") {
                                            setData({ ...data, FATCADeclarations: "2" });
                                        }
                                    }} required
                                    >
                                        <option value="">Select</option>
                                        {ApiData.FATCAStatuslist.map(p => (
                                            <option value={p.id} selected={parseInt(data.FATCAStatus) === p.id}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                    {data.FATCAStatus === "2" ?
                                        <>
                                            <p className="Q" style={{ fontSize: 12 }}>
                                                US person means Citizen / Resident /
                                                Passport holder/Green Card holder/ Tax
                                                Resident as per substantial presence/ Tax
                                                payer for any other reason
                                            </p>
                                        </> :
                                        data.FATCAStatus === "3" ?
                                            <>
                                                <p className="Q" style={{ fontSize: 12 }}>
                                                    US indicia/connection means
                                                    a person who is not a US person but was
                                                    born in USA and / or given standing
                                                    instructions to transfer funds to an
                                                    account maintained in USA and / or have
                                                    any Power of Attorney / Authorized
                                                    Signatory / Mandate holder having US
                                                    address and / or have US residence /
                                                    mailing / Sole Care of address and / or
                                                    have US telephone number.
                                                </p>
                                            </> :
                                            <></>
                                    }
                                </div>
                                {data.FATCAStatus !== "" & data.FATCAStatus !== "1" ?
                                    <>
                                        <div style={{ marginTop: 20 }}>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">FATCA Declarations</h1>
                                            {/* <input className="inputtxt" type="text" name="pob" onChange={(txt) => setvalue(txt)} required /> */}
                                            <select className="inputtxt" name="FATCADeclarations" onChange={(txt) => setvalue(txt)} required disabled
                                            >
                                                <option value="">Select</option>
                                                {ApiData.FATCADeclarationslist.map(p => (
                                                    <option value={p.id} selected={parseInt(data.FATCADeclarations) === p.id} >{p.Name}</option>
                                                ))}
                                            </select>
                                            <p className="Qhelper2"></p>

                                        </div>
                                        <a href={parseInt(data.FATCADeclarations) === 2 ? W8 : W9} className="Q" style={{ marginBottom: 10 }} style={{ fontSize: 12 }} download>Download
                                            ({data.FATCADeclarations?.length === 0 ? "" : ApiData.FATCADeclarationslist.filter(p => p.id === parseInt(data.FATCADeclarations))[0]?.Name})</a>

                                        <div >
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Attach  ({data.FATCADeclarations?.length === 0 ? "" : ApiData.FATCADeclarationslist.filter(p => p.id === parseInt(data.FATCADeclarations))[0]?.Name})</h1>
                                            <input type={"file"} multiple={false} id="WFile" name="WFile" onChange={(txt) => setvalue(txt)} required={
                                                (data.FATCAStatus === data.FATCADeclarations)
                                                    || (data.FATCAStatus === data.FATCADeclarations) ? true : false
                                            } />
                                            <p className="Qhelper" style={{ marginTop: 5, fontSize: 12 }}>Countries in which you are a resident for tax purposes (PNG, PDF, BMP, GIF or JPEG).</p>

                                        </div>

                                        <h1 className="Q" style={{ marginTop: 20 }}>COUNTRY OF TAX RESIDENCE FOR TAX PURPOSES</h1>

                                        <div >
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Country of Residence for Tax Purpose</h1>
                                            <select className="inputtxt" name="taxResidence" onChange={(txt) => setvalue(txt)} required
                                            >
                                                <option value="">Select</option>

                                                {ApiData.Country.map(p => (
                                                    <option value={p.id} selected={parseInt(data.taxResidence) === p.id}>{p.Name}</option>
                                                ))}
                                            </select>
                                            <p className="Qhelper2">Countries in which you are a resident for tax purposes.</p>

                                        </div>
                                        <div>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Tax Identification No. (TIN)</h1>
                                            <input className="inputtxt" type="text" name="TIN" value={data.TIN} onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} />
                                            <p className="Qhelper2">Provide Taxpayer Identification Number TIN &amp; functional equivalent for country indicated.</p>
                                        </div>
                                        <div>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Reason if no TIN available</h1>

                                            <select className="inputtxt" name="TINReason" onChange={(txt) => setvalue(txt)} required={data.TIN !== "" ? false : true}
                                            >
                                                <option value="">Select</option>

                                                {ApiData.TINReasonList.map(p => (
                                                    <option value={p.id} selected={parseInt(data.TINReason) === p.id}>{p.Name}</option>
                                                ))}

                                            </select>
                                            <p className="Qhelper2"></p>
                                        </div>
                                        {data.TINReason + "" === "2" ?
                                            <div>
                                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                                <h1 className="Q">Remarks</h1>
                                                <input className="inputtxt" type="text" name="TINremarks" value={data.TINremarks} onChange={(txt) => CheckAplhaChara(txt, () => { setvalue(txt) }, () => { })} required />
                                                <p className="Qhelper2">Provide Taxpayer Identification Number TIN &amp; functional equivalent for country indicated.</p>
                                            </div>
                                            : <></>
                                        }
                                    </>
                                    : <></>}
                            </fieldset>


                            <div className="btndiv">
                                <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                                    history.push("individual")
                                }>
                                    <ArrowLeft style={{ marginLeft: -10 }} />
                                    Back
                                </button>
                                <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                    <ArrowRight style={{ marginRight: -10 }} />
                                </button>
                            </div>
                        </Row>
                    </Grid>
                    : <></>}
            </Animatedpage>

        </>
    );
}

export default ZakatNTrans;