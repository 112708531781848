import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const Uploadusers = ({ modal, setModal, roles, refreshlist }) => {
    const toggle = () => setModal(false);
    const [isLoaded, setIsLoaded] = useState(true)
    const [Data, setData] = useState([])
    function hasExtension(inputID, exts) {
        var fileName = document.getElementById(inputID).value;
        return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
    }
    function readCSV() {
        var csv = document.getElementById("csvfile").files[0];
        const reader = new FileReader();
        reader.readAsText(csv);
        reader.onload = () => {
            const text = reader.result;
            const csvToJson = csvJSON(text);
            console.log(csvToJson);
        };
    }
    function csvJSON(csv) {
        //var csv = document.getElementById("csvfile").files[0];

        if (csv !== null) {

            var lines = csv.split("\n");
            var result = [];
            var headers = lines[0].split(",");

            for (var i = 1; i < lines.length; i++) {

                var obj = {};
                var currentline = lines[i].split(",");

                for (var j = 0; j < headers.length; j++) {
                    if (headers[j].trim() === "Employee Code" || headers[j].trim() === "EmployeeCode") {
                        obj["EmployeeCode"] = currentline[j];
                    } else if (headers[j].trim() === "Employee Name" || headers[j].trim() === "EmployeeName") {
                        obj["EmployeeName"] = currentline[j];
                    } else if (headers[j].trim() === "Email") {
                        obj["Email"] = currentline[j];
                    } else if (headers[j].trim() === "CNIC") {
                        obj["CNIC"] = currentline[j];
                    }
                }
                if (obj[headers[0]] !== null && obj[headers[1]] !== null) {
                    result.push(obj);
                }

            }
            setData([...result]); //JSON
            return result
        }
    }

    const uploaddata = async (event) => {

        event.preventDefault();
        setIsLoaded(false)
        if (Data.length > 0) {
            var senddata = { "Userlist": Data };
            var data = await postRequest("api/dashboard/DigitalAcountDashboard/AddBankReferralsCSV", Data);
            if (data.StatusCode === 0) {
                alert("Users Added.")
                refreshlist()
                toggle();
            } else {
                alert(data.StatusMessage);
            }
        } else {
            alert("No data found");
        }
        setIsLoaded(true)


    }

    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>

            <form onSubmit={uploaddata}>
                <ModalHeader>Add User CSV</ModalHeader>
                <ModalBody style={{ width: "fit-content" }}>
                    {!isLoaded ? (
                        <div style={{ textAlign: "center" }}>
                            {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                            <div className={`load${true ? '' : ' loaded'}`}>

                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ) :
                        <>
                            <input type={"file"} accept=".csv" id='csvfile' multiple={false} onChange={(e) => { readCSV() }} />

                            <Row>

                                <table className='table table-bordered table-stripe' style={{ marginTop: 50 }}>
                                    <thead>
                                        <tr>

                                            <th>EmployeeCode</th>
                                            <th>EmployeeName</th>
                                            <th>Email</th>
                                            <th>CNIC</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.map(p => (
                                            <tr>
                                                <td>{p.EmployeeCode}</td>
                                                <td>{p.EmployeeName}</td>
                                                <td>{p.Email}</td>
                                                <td>{p.CNIC}</td>

                                            </tr>

                                        ))}


                                    </tbody>
                                </table>


                            </Row>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Col md={12}>
                        <button type='submit' className="btn" style={{ marginLeft: 5, backgroundColor: "var(--faysal)", color: "white" }}>Upload</button>
                        <button className="btn" style={{ marginLeft: 5, backgroundColor: "var(--islamic)", color: "white" }} onClick={toggle}>Cancel</button>
                    </Col>
                </ModalFooter>
            </form>

        </Modal>

    );
}
export default Uploadusers;