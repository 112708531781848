import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const AddUser = ({ modal, setModal, roles,refreshlist }) => {
    const toggle = () => setModal(false);
    const [InviteEmail, setInviteEmail] = useState("");
    const [DomainId, setDomainId] = useState("");
    const [Remarks, setRemarks] = useState("");
    const [EmployeeCode, setEmployeeCode] = useState("");
    const [Roles, setRoles] = useState()
    const [ActiveStatus, setActiveStatus] = useState(true)
    const [isLoaded, setIsLoaded] = useState(true)

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const Invite = async (event) => {
        event.preventDefault();
        setIsLoaded(false)
        if (validateEmail(DomainId)) {
            var senddata = { "UserDomainId": DomainId, "EmployeeCode": EmployeeCode, "FullName": InviteEmail, "Remarks": Remarks, "RoleId": Roles, "IsActivated": ActiveStatus };
            var data = await postRequest("api/dashboard/Auth/AddUser", senddata);
            if (data.StatusCode === 0) {
                alert("User Added.")
                setInviteEmail("");
                setDomainId("");
                setRemarks("");
                setEmployeeCode("");
                refreshlist();
                toggle();
            } else {
                alert(data.StatusMessage);
            }
        } else {
            alert("Domain Id must be in email format");
        }
        setIsLoaded(true)
    }
    useEffect(() => {
    }, [])
    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>

            <form onSubmit={Invite}>
                <ModalHeader>Add User</ModalHeader>
                <ModalBody style={{ textAlign: "left" }}>
                    {!isLoaded ? (
                        <div style={{ textAlign: "center" }}>
                            {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                            <div className={`load${true ? '' : ' loaded'}`}>

                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ) :
                        <Row>
                            <Col md={12}>
                                <h3 className="page-title">Add User</h3>
                            </Col>
                            <Col md={12}>
                                <label>User Domain Id</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={DomainId}
                                    onChange={(txt) => { setDomainId(txt.target.value); }}
                                />

                            </Col>
                            <Col md={12}>
                                <label>Employee Code</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={EmployeeCode}
                                    onChange={(txt) => { setEmployeeCode(txt.target.value); }}
                                />

                            </Col>
                            <Col md={12}>
                                <label>Full Name</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={InviteEmail}
                                    onChange={(txt) => { setInviteEmail(txt.target.value); }}
                                />

                            </Col>

                            <Col md={6}>
                                <label>Role</label>
                                <select
                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    onChange={(txt) => { setRoles(txt.target.value); }}
                                >
                                    <option value="">Select</option>
                                    {roles.map(o => (
                                        <option value={o.Id}>{o.Name}</option>
                                    ))}
                                </select>

                            </Col>
                            <Col md={6}>
                                <label>Is Active</label>
                                {ActiveStatus ?
                                    <RadioButtonChecked onClick={() => setActiveStatus(!ActiveStatus)} />
                                    :
                                    <RadioButtonUnchecked onClick={() => setActiveStatus(!ActiveStatus)} />

                                }

                            </Col>

                            <Col md={12}>
                                <label>Remarks</label>
                                <textarea
                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    rows={3}
                                    onChange={(txt) => { setRemarks(txt.target.value); }}
                                >{Remarks}</textarea>

                            </Col>

                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Col md={12}>
                        <button type='submit' className="btn" style={{ marginLeft: 5, backgroundColor: "var(--faysal)", color: "white" }}>Add User</button>
                        <button className="btn" style={{ marginLeft: 5, backgroundColor: "var(--islamic)", color: "white" }} onClick={toggle}>Cancel</button>
                    </Col>
                </ModalFooter>
            </form>

        </Modal>

    );
}
export default AddUser;