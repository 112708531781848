import { Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "./Data.Context";
import Modal from '@material-ui/core/Modal';
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { ResentOtp, VerifyOtp } from "../service";
import { useHistory } from "react-router";

const AlertDataContext = React.createContext({});

const AlertDataContextProvider = ({ children }) => {
    const [error, setError] = useState(false)
    const [errorstr, setErrorstr] = useState("")
    const [info, setInfo] = useState(false)
    const [infostr, setInfostr] = useState("")
    const [loading, setLoading] = useState(false);
    const [OTPModal, setOTPModal] = useState(false);
    const [countdown, setcountdown] = useState(60);
    const [currentScreen, setCurrentScreen] = useState("")
    const [otp, setotp] = useState("")
    const { data } = useContext(DataContext);

    //DynamicModel States
    const [DynamicModel, setDynamicModel] = useState(false);
    const [DynamicModelBody, setDynamicModelBody] = useState();
    const [DynamicModelHeading, setDynamicModelHeading] = useState("");
    const [DynamicModelShowClose, setDynamicModelShowClose] = useState(false)

    const DynamicModelShow = (heading, body, close) => {
        setDynamicModel(true);
        setDynamicModelBody(body);
        setDynamicModelShowClose(close);
        setDynamicModelHeading(heading);
    }

    const history = useHistory();

    const setmOTP = async (screen) => {
        setLoading(true);
        var resp = await ResentOtp(data);
        setCurrentScreen(screen)
        setOTPModal(true);
        setLoading(false);
        startcountdown();

    }
    const showError = (ErrorString) => {
        setErrorstr(ErrorString);
        setError(true);
        var t = setTimeout(() => {
            setError(false);
            clearTimeout(t);
        }, 3000);
    }
    const showInfo = (InfoString) => {
        setInfostr(InfoString);
        setInfo(true);
        var t = setTimeout(() => {
            setInfo(false);
            clearTimeout(t);
        }, 3000);
    }
    const startcountdown = async () => {

        var t = setInterval(() => {
            if (countdown > -1) {
                var s = countdown - 1;
                setcountdown(prevTime => prevTime - 1);
            }
        }, 1000);
        var s = setTimeout(() => {
            clearInterval(t);
            clearTimeout(s)
        }, 1000 * 60);
    }
    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        var resp = await VerifyOtp(data, otp);
        setLoading(false);
       
        if (resp.StatusCode === 0) {
            // if (history.location.pathname === currentScreen) {
            window.location.reload();
            // }
            // else {
            //     history.push(currentScreen);
            // }
        } else {
            showError("Some Error occurs. Please check your details");
        }
    }
    const DynamicModelHide = () => {
        setDynamicModel(false);
    }
    return (
        <AlertDataContext.Provider value={{
            showInfo,
            showError,
            setLoading,
            setmOTP,
            DynamicModelShow,
            DynamicModelHide

        }}>
            {
                loading ?
                    <div style={{ width: "100vw", height: "100vh", background: "#0000005c", position: "fixed", top: 0, left: 0, zIndex: 9999 }}>
                        <div class="loader"></div>
                        <h3 style={{ margin: "auto", color: "#fff", marginTop: 10, textAlign: "center" }}>Please wait.</h3>
                    </div>
                    : <></>
            }
            {error ?
                <div style={{ position: "absolute", bottom: 10, left: 20 }}>
                    <Slide direction="right" in={true}>
                        <Alert severity="error">{errorstr}</Alert>
                    </Slide>
                </div> :
                null
            }
            {info ?
                <div style={{ position: "absolute", bottom: 10, left: 20 }}>
                    <Slide direction="right" in={true}>
                        <Alert severity="info">{infostr}</Alert>
                    </Slide>
                </div> :
                null
            }
            {OTPModal ?
                <div style={{ position: "absolute", bottom: 10, left: 20 }}>
                    <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                    // className={classes.modal}
                    // container={() => rootRef.current}
                    >
                        <div style={{ width: "50vw", height: "50vh", background: "white", alignSelf: "center", margin: "auto", marginTop: "10vh", borderRadius: "20px", padding: 20 }}>
                            <form onSubmit={submit}>
                                <h2 id="Qhelper">Session Expired</h2>
                                <p id="server-modal-description">Enter the OTP code send to your mobile number.</p>
                                <input className="inputtxt" name="otp" autoFocus={true} onChange={(txt) => setotp(txt.target.value)} required />
                                <p className="Qhelper">OTP expire in 00:{countdown < 10 ? "0" + countdown : countdown}</p>
                                <button type="submit" variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                    <ArrowLeft style={{ marginRight: -10 }} />
                                </button >
                            </form>
                        </div>
                    </Modal>
                </div> :
                null
            }

            {DynamicModel ?
                <div style={{ position: "absolute", bottom: 10, left: 20 }} >
                    <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                    // className={classes.modal}
                    // container={() => rootRef.current}
                    >
                        <div className="DynamicModelcls" style={{ width: "50vw", height: "fit-content", background: "white", alignSelf: "center", margin: "auto", marginTop: "10vh", borderRadius: "20px", padding: 20 }}>
                            <form onSubmit={submit}>
                                <h2 id="Qhelper">{DynamicModelHeading}</h2>
                                <div className="dymodelbody">
                                    {DynamicModelBody}
                                    {DynamicModelShowClose ?
                                        <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                            onClick={() => setDynamicModel(false)}
                                        >Close
                                            <ArrowRight style={{ marginRight: -10 }} />
                                        </button> :
                                        <></>
                                    }
                                </div>
                            </form>
                        </div>
                    </Modal>
                </div> :
                null
            }

            {children}
        </AlertDataContext.Provider>
    )
};

export {
    AlertDataContext,
    AlertDataContextProvider
};