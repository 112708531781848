import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { ApplicationStatusesEnum } from '../Enums';
import { CheckAplhaChara, getFileData, KeyValue, postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const Meeting = ({ modal, setModal, id, index, statusid }) => {
    const toggle = () => setModal(false);
    const [Loading, setLoading] = useState(false);
    const history = useHistory();
    const [ecrp, setecrp] = useState(null);
    const submit = async () => {

        if (ecrp === null) {
            alert("Please choose atleast one file.");
            return;
        }
        setLoading(true);
        var request_data = new FormData();
        var Meeting = new KeyValue("Meeting", document.getElementById("meeting")?.files[0]);
        var data = {
            "Id": id, "Index": index
        };
        request_data = getFileData(data, Meeting);
        var t = await postRequest("api/dashboard/DigitalAcountDashboard/UserMeeting",
            request_data);
        if (t.StatusCode === 0) {
            alert("Meeting uploaded.");
            window.location.reload();
        } else {
            alert("Some Error Occurs please try again.");
            console.error(t);
        }
        setLoading(false);
    }
    useEffect(() => {
    }, [])
    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>
            <ModalHeader toggle={toggle}>Add Meeting</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="form__form-group" style={{ marginTop: 0 }}>
                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                Upload/Add Meeting</span>
                            <div className="form__form-group-field">
                                <input type={"file"} id="meeting" onChange={(txt) => setecrp(txt.target?.files[0])} multiple={false} accept="video/*" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => submit()} disabled={Loading}>Submit</Button>{' '}
                <Button color="secondary" onClick={toggle} disabled={Loading}>Cancel</Button>
            </ModalFooter>
        </Modal>

    );
}
export default Meeting;