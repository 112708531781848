import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { CheckAlpha, CheckAplhaChara, CheckNumeric, postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const AddUser = ({ modal, setModal, roles, refreshlist }) => {
    const toggle = () => setModal(false);
    const [InviteEmail, setInviteEmail] = useState("");
    const [CNIC, setCNIC] = useState("");
    const [Email, setEmail] = useState("");
    const [EmployeeCode, setEmployeeCode] = useState("");
    const [Roles, setRoles] = useState()
    const [ActiveStatus, setActiveStatus] = useState(true)
    const [isLoaded, setIsLoaded] = useState(true)

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const Invite = async (event) => {
        event.preventDefault();
        setIsLoaded(false)
        if (validateEmail(Email)) {
            var senddata = { "Email": Email, "EmployeeCode": EmployeeCode, "EmployeeName": InviteEmail, "CNIC": CNIC };
            var data = await postRequest("api/dashboard/DigitalAcountDashboard/AddBankReferrals", senddata);
            if (data.StatusCode === 0) {
                alert("User Added.")
                setInviteEmail("");
                setCNIC("");
                setEmail("");
                setEmployeeCode("");
                refreshlist();
                toggle();
            } else {
                alert(data.StatusMessage);
            }
        } else {
            alert("Domain Id must be in email format");
        }
        setIsLoaded(true)
    }
    useEffect(() => {
    }, [])
    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>

            <form onSubmit={Invite}>
                <ModalHeader>Add User</ModalHeader>
                <ModalBody style={{ textAlign: "left" }}>
                    {!isLoaded ? (
                        <div style={{ textAlign: "center" }}>
                            {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                            <div className={`load${true ? '' : ' loaded'}`}>

                                <div className="load__icon-wrap">
                                    <svg className="load__icon">
                                        <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ) :
                        <Row>
                            <Col md={12}>
                                <h3 className="page-title">Add Branch User Referal</h3>
                            </Col>
                            <Col md={12}>
                                <label>Employee Code</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={EmployeeCode}
                                    onChange={(txt) => { CheckNumeric(txt, () => { setEmployeeCode(txt.target.value); }, () => { }); }}
                                />

                            </Col>
                            <Col md={12}>
                                <label>Full Name</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={InviteEmail}
                                    onChange={(txt) => { CheckAlpha(txt, () => { setInviteEmail(txt.target.value); }, () => { }); }}
                                />

                            </Col>
                            <Col md={12}>
                                <label>Email</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="email"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={Email}
                                    onChange={(txt) => { CheckAlpha(txt, () => { setEmail(txt.target.value); }, () => { }); }}
                                />

                            </Col>
                            <Col md={12}>
                                <label>CNIC</label>
                                <input

                                    style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5, marginBottom: 5 }}
                                    name="name"
                                    type="number"
                                    autoComplete="off"
                                    placeholder=""
                                    required
                                    value={CNIC}
                                    onChange={(txt) => { CheckNumeric(txt, () => { setCNIC(txt.target.value); }, () => { }); }}
                                />

                            </Col>


                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Col md={12}>
                        <button type='submit' className="btn" style={{ marginLeft: 5, backgroundColor: "var(--faysal)", color: "white" }}>Add User</button>
                        <button className="btn" style={{ marginLeft: 5, backgroundColor: "var(--islamic)", color: "white" }} onClick={toggle}>Cancel</button>
                    </Col>
                </ModalFooter>
            </form>

        </Modal>

    );
}
export default AddUser;