import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { postRequest } from '../service';

const Logout = () => {
    const history = useHistory();

    useEffect(async() => {
        var t = await postRequest("api/dashboard/Auth/Logout", {});
        if (t.StatusCode === 0) {
            history.push("/admin/login");
        } 
    }, [])

    return (
        <div style={{ textAlign: "center" }}>
            {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
            <div className={`load${true ? '' : ' loaded'}`}>

                <div className="load__icon-wrap">
                    <svg className="load__icon">
                        <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                </div>
            </div>
        </div>
    )

}

export default Logout;

