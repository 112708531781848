import React from 'react';
import ReactDOM from 'react-dom';
import Admin from './admin/Main';
import './index.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { FunctionsContextProvider } from '../components/Context/Functions.context';


export default function Index() {
  return (
    <>
      <Switch>
        <Redirect exact from='/admin' to='/admin/login' />
        <Redirect exact from='/' to='/admin/login' />
        <Route path="/app" component={Admin} />
        <Route path="/admin" component={Admin} />
      </Switch>

    </>

  );

}
